import {
  Box,
  Container,
  Divider,
  Grid,
  Heading,
  LinkBox,
  LinkOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import Author from 'components/common/author';
import { HubSpotCTAProps } from 'components/common/RichText/HubSpotCTA';
import { ThreeColumnGrid } from 'components/common/ThreeColumnGrid';
import { useAnchorsObserver } from 'lib/hooks/useAnchorsObserver';
import { useTranslation } from 'lib/hooks/useTranslation';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import NextLink from 'next/link';
import { Locale, Storyblok } from 'types';
import { formatDate } from 'utils/formatDate';
import { BlogArticleTags } from '../common/BlogArticleCategories';
import BlogCategory = Storyblok.BlogCategory;

const Summary = dynamic(() =>
  import('components/common/Summary').then(mod => mod.Summary),
  { ssr: true }
);

const ShareButtons = dynamic(() =>
  import('components/common/ShareButtons').then(mod => mod.ShareButtons),
  { ssr: false }
);

const CallToActions = dynamic(() =>
  import('components/common/CallToActions'),
  { ssr: false }
);

const ArticleRichText = dynamic(
  () => import('components/common/ArticleRichText'),
  { ssr: true }
);

// LatestArticles moved to dynamic client-only import
const LatestArticles = dynamic(() =>
  Promise.resolve(function LazyLatestArticles(props: LatestArticlesProps) {
    const { useTranslation } = require('lib/hooks/useTranslation');
    const { locale } = useTranslation();
    const { latestArticlesTitle, latestArticles } = props;

    return (
      <Box p="16px" borderRadius="8px" boxShadow="lg">
        <Heading as="p" size="xs" mb="16px">
          {latestArticlesTitle}
        </Heading>
        <VStack divider={<Divider bg="neutral.100" />} spacing="16px" align="left">
          {latestArticles.map((article, i) => {
            const normalizedTitle = article.content.title.replace(/(.+)( )([?!.])$/, '$1\u00a0$3');
            return (
              <LinkBox
                key={i}
                borderRadius="8px"
                sx={{ _hover: { bg: 'azure.8' } }}
                margin="-8px"
                padding="8px"
                transition="background-color 200ms ease-out"
              >
                <NextLink href={`/blog/${article.content.primaryCategory.slug}/${article.slug}`} passHref>
                  <LinkOverlay fontSize="14px" lineHeight="20px">
                    {normalizedTitle}
                  </LinkOverlay>
                </NextLink>
                <Text fontSize="12px" lineHeight="1" mt="8px">
                  {formatDate(article.content.date, 'PP', locale)}
                </Text>
              </LinkBox>
            );
          })}
        </VStack>
      </Box>
    );
  }),
  { ssr: false }
);

const OBSERVED_ANCHOR_CLASS_NAME = 'article-anchor';

type ArticleSectionProps = {
  metadata: Storyblok.Metadata;
  title: string;
  image: Storyblok.Image;
  author: Storyblok.Author;
  date: string;
  content: Storyblok.RichText;
  anchorHeadings: {
    id: string;
    name: string;
  }[];
  customAnchorHeadings: {
    url: string;
    newName: string;
  }[];
  leftSidebarTitle: string;
  rightSidebarCallToActions: HubSpotCTAProps[];
  latestArticlesTitle: string;
  latestArticles: {
    slug: string;
    content: {
      title: string;
      date: string;
      primaryCategory?: { slug: string };
    };
  }[];
  primaryCategory: BlogCategory;
  otherCategories?: BlogCategory[];
};

type LatestArticlesProps = Pick<ArticleSectionProps, 'latestArticlesTitle' | 'latestArticles'>;

export default function ArticleSection({
  metadata,
  title,
  image,
  author,
  date,
  content,
  anchorHeadings,
  customAnchorHeadings,
  leftSidebarTitle,
  rightSidebarCallToActions,
  latestArticlesTitle,
  latestArticles,
  primaryCategory,
  otherCategories,
}: ArticleSectionProps) {
  const { locale } = useTranslation();
  const currentAnchorId = useAnchorsObserver(OBSERVED_ANCHOR_CLASS_NAME);

  return (
    <Container as="article">
      <ThreeColumnGrid
        center={
          <>
            <Heading as="h1" size="2xl">
              {title}
            </Heading>
            <BlogArticleTags mt="16px" primaryCategory={primaryCategory} otherCategories={otherCategories} />
            <Author mt="40px" mb="32px" author={author} date={date} locale={locale as Locale} />
          </>
        }
      />
      <ThreeColumnGrid
        left={
          <Summary
            activeItemId={currentAnchorId}
            title={leftSidebarTitle}
            listItems={anchorHeadings}
            customListItems={customAnchorHeadings}
          />
        }
        center={
          <>
            <Box
              as="figure"
              width="100%"
              pt="43.3%"
              borderRadius="8px"
              overflow="hidden"
              position="relative"
              mb="32px"
            >
              <Image src={image.filename} alt="" layout="fill" objectFit="cover" sizes="720px" priority />
            </Box>
            <ArticleRichText anchorClassName={OBSERVED_ANCHOR_CLASS_NAME} content={content} />
          </>
        }
        right={
          <Grid gridGap="16px">
            {rightSidebarCallToActions?.length > 0 && (
              <CallToActions callToActions={rightSidebarCallToActions} />
            )}
            {latestArticles?.length > 0 && (
              <LatestArticles
                latestArticlesTitle={latestArticlesTitle}
                latestArticles={latestArticles}
              />
            )}
            <ShareButtons title={metadata.title} description={metadata.description} />
          </Grid>
        }
      />
    </Container>
  );
}
