import { VFC } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { SectionProps } from 'types';

type ArticleFaqProps = SectionProps<{
  faq: { question: string; answer: string; _uid: string }[];
}>;

const ArticleFaq: VFC<ArticleFaqProps> = ({ faq }) => {
  return (
    <Box>
      {faq.map((e) => (
        <Box key={e._uid}>
          <Text fontWeight={700}>{`Q: ${e.question}`}</Text>
          <Text>{`A: ${e.answer}`}</Text>
        </Box>
      ))}
    </Box>
  );
};

export default ArticleFaq;
